import React from "react";
import { SplOfferData } from "../../../constants";
import ProductSkeleton from "../../ProductSkeleton/ProductSkeletion";
import { useCatProductsQuery } from "../../../store/services/homeProducts";
import { Link, useNavigate } from "react-router-dom";
import currency  from "currency-formatter";
import Product from "../../home/Products/Product";
import { discount } from "../../../utils/discount";
const ProductsOnSale = ({category,filterid}) => {
  const navigate = useNavigate();
  const { data, isFetching } = useCatProductsQuery({
    name:category,
    // page: parseInt(page),
  });

  
  return (
    <div className="w-full grid grid-cols-2 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10 mb-20">
      {isFetching ? 
          <ProductSkeleton />:
          
          data?.products.filter(
            (item) => item._id !== filterid
          ).map((item) => {
            return( <div key={item._id} className="px-2">
               <Product
               sizes={item.sizes}
                 _id={item._id}
                 img={`/images/${item.images[0]}`}
                 productName={item.title}
                price={item?.price || discount(item.sizes[0].price,item?.discount)}
                 color="Black"
                 badge={true}
                 des="School Product"
               />
             </div>
           )})}
    </div>
  );
};

export default ProductsOnSale;
