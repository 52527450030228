import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import ProductSkeleton from "../../ProductSkeleton/ProductSkeletion";
import { useCatProductsQuery } from "../../../store/services/homeProducts";
import { discount } from "../../../utils/discount";

const NewArrivals = ({ category }) => {

  const { data, isFetching } = useCatProductsQuery({
    name: category.name,
    page: "",
  });
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    infinite: data?.products?.length >= 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: data?.products?.length >= 3,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: data?.products?.length >= 2,
        },
      },
    ],
  };


  return (
    data?.products.length > 0 &&<>  <div className="w-full pb-16">
    <Heading heading={category.name} />
      {isFetching ? (
        <ProductSkeleton />
      ) : (
        <div className="w-full">
          <Slider {...settings}>
            {data?.products?.map((item) => (
              <div key={item._id} className="px-2">
                <Product
                sizes={item.sizes}
                  _id={item._id}
                  img={`/images/${item.images[0]}`}
                  productName={item.title}
                  price={item?.price || discount(item.sizes[0].price,item?.discount)}
                  color="Black"
                  badge={true}
                  des="Exclusive New Products ."
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
      
    </div></>
  );
};

export default NewArrivals;