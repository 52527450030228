import './product-image-slider.scss'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper'
import { useState } from 'react'
import ReactSlick from 'react-slick';
import ReactImageMagnify from 'react-image-magnify';

const ProductImagesSlider = props => {
    const [activeThumb, setActiveThumb] = useState()

    return <>
        <Swiper
            loop={true}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation, Thumbs]}
            grabCursor={true}
            thumbs={{ swiper: activeThumb }}
            className='product-images-slider'
        >
            {
                props.images.map((item, index) => (
                    <SwiperSlide  key={index}>
                           <ReactImageMagnify
                            {...{
                                smallImage: {
                                    alt:"product images",
                                    isFluidWidth: true,
                                    src: `/images/${item}`,
                                    width: 350,
                                    height: 450
                                },
                                largeImage: {
                                    src: `/images/${item}`,
                                    width: 400,
                                    height: 1300
                                },
                                lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
                                isHintEnabled: true,
                                shouldHideHintAfterFirstActivation: false,
                                enlargedImagePosition: 'over',
                                enlargedImageContainerDimensions: {
                                    width: '100%',
                                    height: '100%'
                                },
                            }} 
                        />
                        {/* <img src={`http://3.108.236.155:4040/images/${item}`} alt="product images" /> */}
                    </SwiperSlide>
                ))
            }
        </Swiper>
        <Swiper
            onSwiper={setActiveThumb}
            // loop={true}
            spaceBetween={10}
            slidesPerView={4}
            modules={[Navigation, Thumbs]}
            className='product-images-slider-thumbs'
        >
            {
                props.images.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="product-images-slider-thumbs-wrapper">
                            <img src={`/images/${item}`} alt="product images" />
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    </>
}

ProductImagesSlider.propTypes = {
    images: PropTypes.array.isRequired
}

export default ProductImagesSlider