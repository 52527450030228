import { useParams, Link } from "react-router-dom";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { BsPrinter } from "react-icons/bs";
import currency from "currency-formatter";
import moment from "moment";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import Spinner from "../../components/Spinner";
import {
  useDetailsQuery,
  useDeliverOrderMutation,
} from "../../store/services/orderService";
import { discount } from "../../utils/discount";

const OrderDetails = () => {
  const { id } = useParams();
  const componentRef = useRef();
  const { data, isFetching } = useDetailsQuery(id);
  console.log(data);
  const total =
    discount(
      data?.details?.productId?.sizes?.find(
        (size) => size.name === data?.details?.size
      )?.price || 0,
      data?.details?.productId?.discount
    ) * data?.details?.quantities;
  const [sentUserOrder, response] = useDeliverOrderMutation();
  const sentOrder = (id, status) => {
    sentUserOrder({ id, status });
  };
  return (
    <Wrapper>
      <ScreenHeader>
        <div className="flex items-center">
          <Link to="/dashboard/orders">
            <MdOutlineKeyboardBackspace />
          </Link>
          <span className="ml-4"> Order Details</span>
          <span className="ml-4">
            <ReactToPrint
              trigger={() => (
                <button
                  className="flex items-center btn bg-whitepy-1 text-sm font-semibold px-3"
                  style={{ color: "white" }}
                >
                  <BsPrinter /> <span className="ml-2">print</span>
                </button>
              )}
              content={() => componentRef.current}
            />
          </span>
          <span className="ml-4">
            {/* {!isFetching && !data?.details?.status && ( */}
            <select
              className={`btn ${
                data?.details?.status == 3 ? "bg-danger" : "bg-success"
              } py-1 text-sm font-semibold px-3`}
              // className="py-1 px-2 text-sm font-semibold border rounded"
              onChange={(e) => sentOrder(data?.details?._id, e.target.value)}
              defaultValue=""
            >
              <option value="" disabled>
                {{ 0: "Pending", 1: "Shipped", 2: "Delivered", 3: "Cancelled" }[
                  data?.details?.status
                ] || "Unknown"}
              </option>
              <option value="pending">Pending</option>
              <option value="shipped">Shipped</option>
              <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
            </select>
            {/* )} */}
          </span>
        </div>
      </ScreenHeader>
      {!isFetching ? (
        // <div ref={componentRef}>
        //   <h3 className="capitalize text-gray-400">
        //     order number:{" "}
        //     <span className="text-lg text-gray-300 ml-4">
        //       #{data?.details?._id}
        //     </span>
        //   </h3>
        //   <h3 className="capitalize text-gray-400 mt-2">
        //     order date:{" "}
        //     <span className="text-sm text-gray-300 ml-4">
        //       {moment(data?.details?.createdAt).format("MMMM Do YYYY")}
        //     </span>
        //   </h3>
        //   {data?.details?.received && (
        //     <h3 className="capitalize text-gray-400 mt-2">
        //       received date:{" "}
        //       <span className="text-sm text-gray-300 ml-4">
        //         {moment(data?.details?.updatedAt).format("MMMM Do YYYY")}
        //       </span>
        //     </h3>
        //   )}

        //   <div className="flex flex-wrap -mx-5">
        //     <div className="w-full md:w-8/12 p-5">
        //       <div>
        //         <table className="bg-transparent border-gray-600 rounded-none md:rounded-md dashboard-table">
        //           <thead>
        //             <tr className="dashboard-tr">
        //               <th className="dashboard-th">image</th>
        //               <th className="dashboard-th">quantities</th>
        //               <th className="dashboard-th">price</th>
        //               <th className="dashboard-th">size</th>
        //               <th className="dashboard-th">color</th>
        //               <th className="dashboard-th">total</th>
        //             </tr>
        //           </thead>
        //           <tbody>
        //             <tr>
        //               <td className="dashboard-td">
        //                 <img
        //                   src={`/images/${data?.details?.productId?.images[0]}`}
        //                   alt="image name"
        //                   className="w-[50px] h-[50px] rounded-full object-cover"
        //                 />
        //               </td>
        //               <td className="dashboard-td">
        //                 {data?.details?.quantities}
        //               </td>
        //               <td className="dashboard-td">
        //                 {currency.format(
        //                   discount(
        //                     data?.details?.productId?.price,
        //                     data?.details?.productId?.discount
        //                   ),
        //                   { code: "INR" }
        //                 )}
        //               </td>
        //               <td className="dashboard-td">
        //                 {data?.details?.size ? data?.details?.size : "No size"}
        //               </td>
        //               <td className="dashboard-td">
        //                 <span
        //                   className="block w-[15px] h-[15px] rounded-full"
        //                   style={{ background: data?.details?.color }}
        //                 ></span>
        //               </td>
        //               <td className="dashboard-td">
        //                 {currency.format(total, { code: "INR" })}
        //               </td>

        //             </tr>
        //           </tbody>
        //         </table>
        //       </div>
        //     </div>
        //     <div className="w-full md:w-4/12 p-5">
        //       <div className="border border-gray-600 rounded-none md:rounded-md p-4">
        //         <div className="border-b pb-3 border-b-gray-600">
        //           <h4 className="capitalize text-base text-gray-500">
        //             customer name
        //           </h4>
        //           <span className="text-gray-400 text-base font-medium capitalize mt-2">
        //             {data?.details?.userId?.name}
        //           </span>
        //         </div>
        //         <div className="border-b pb-3 border-b-gray-600">
        //           <h4 className="capitalize text-base text-gray-500">
        //             product name
        //           </h4>
        //           <span className="text-gray-400 text-base font-medium capitalize mt-2">
        //             {data?.details?.productId?.title}
        //           </span>
        //         </div>

        //         <div>
        //           <h4 className="capitalize text-base text-gray-500 mt-2">
        //             shipping address
        //           </h4>
        //           <div className="mt-2">
        //             <span className="text-gray-400 capitalize block">
        //               {data?.details?.address?.city}
        //             </span>
        //             <span className="text-gray-400 capitalize block">
        //               {data?.details?.address?.line1}
        //             </span>
        //             <span className="text-gray-400 capitalize block">
        //               {data?.details?.address?.line2}
        //             </span>
        //             <span className="text-gray-400 capitalize block">
        //               {data?.details?.address?.postal_code}
        //             </span>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <div ref={componentRef} className="w-[210mm] h-[297mm] mx-auto bg-white p-8 shadow-lg">
        {/* Header Section */}
        <div className="text-center mb-8">
          <h1 className="text-2xl font-bold mb-2" style={{ color: '#110572', fontFamily: 'Arial' }}>
            THE BOOK WALE
          </h1>
          <p className="text-sm" style={{ color: '#110572', fontFamily: 'Verdana' }}>
            242J, Singrauli
          </p>
          <p className="text-sm" style={{ color: '#110572', fontFamily: 'Verdana' }}>
          Madhya Pradesh - 486886
          </p>
          <p className="text-sm" style={{ color: '#110572', fontFamily: 'Verdana' }}>
            E-Mail: aarya@thebookwale.com
          </p>
          <div className="mt-4 text-lg font-bold" style={{ color: '#110572', fontFamily: 'Verdana' }}>
            GST INVOICE
          </div>
          <p className="text-xs" style={{ color: '#110572', fontFamily: 'Arial' }}>
            GSTIN: 23ACHFA7743F1ZJ
          </p>
        </div>
  
        {/* Invoice Details Grid */}
        <div className="grid grid-cols-2 gap-8 mb-8">
          <div className="space-y-2">
            <p className="text-sm" style={{ color: 'black', fontFamily: 'Arial' }}>
              PH.NO.: 8989507009
            </p>
          </div>
          <div className="space-y-2 text-right">
            <p className="text-sm" style={{ color: 'black', fontFamily: 'Arial' }}>
              <span className="font-bold">Date: </span>
              {moment(data?.details?.createdAt).format("MMMM Do YYYY")}
            </p>
            <p className="text-sm" style={{ color: 'black', fontFamily: 'Arial' }}>
              <span className="font-bold">Order No: </span>
              {data?.details?.orderId}
            </p>
            <p className="text-sm" style={{ color: 'black', fontFamily: 'Arial' }}>
              <span className="font-bold">Update Date: </span>
              {moment(data?.details?.updatedAt).format("MMMM Do YYYY")}
            </p>
          </div>
        </div>
  
        {/* Products Table */}
        <div className="overflow-x-auto mb-8" style={{ maxWidth: '100%' }}>
  <table 
    className="w-full text-sm" 
    style={{
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
    }}
  >
            <thead>
              <tr style={{ backgroundColor: '#C1F9FC' }}>
                <th className="py-2 text-right border font-bold" style={{ color: 'black', fontFamily: 'Verdana',width: '5%' }}>S.No</th>
                <th className="py-2 px-4 text-left border font-bold" style={{ color: 'black', fontFamily: 'Verdana', width: '20%' }}>Product</th>
                <th className="py-2 px-4 text-left border font-bold" style={{ color: 'black', fontFamily: 'Verdana',width: '10%' }}>Size</th>
                <th className="py-2 px-4 text-right border font-bold" style={{ color: 'black', fontFamily: 'Verdana', width: '8%' }}>HSN</th>
                <th className="py-2 px-4 text-right border font-bold" style={{ color: 'black', fontFamily: 'Verdana', width: '8%' }}>MRP</th>
                <th className="py-2 px-4 text-right border font-bold" style={{ color: 'black', fontFamily: 'Verdana', width: '8%' }}>Rate</th>
                <th className="py-2 px-4 text-center border font-bold" style={{ color: 'black', fontFamily: 'Verdana' , width: '7%'}}>Qty</th>
                <th className="py-2 px-4 text-right border font-bold" style={{ color: 'black', fontFamily: 'Verdana', width: '7%', fontSize: '6pt' }}>SGST</th>
                <th className="py-2 px-4 text-right border font-bold" style={{ color: 'black', fontFamily: 'Verdana', width: '7%' ,fontSize: '6pt' }}>CGST</th>
                <th className="py-2 px-4 text-right border font-bold" style={{ color: 'black', fontFamily: 'Verdana', width: '12%' }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {data?.details?.products.map((item, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 border" style={{ color: 'black', fontFamily: 'Arial', fontSize: '7pt' }}>{index + 1}</td>
                  <td className="py-2 px-4 border" style={{ color: 'black', fontFamily: 'Verdana', fontSize: '6pt' }}>{item?.productId?.title}</td>
                  <td className="py-2 px-4 border" style={{ color: 'black', fontFamily: 'Arial', fontSize: '7pt' }}>{item?.size || "No size"}</td>
                  <td className="py-2 px-4 text-right border" style={{ color: 'black', fontFamily: 'Arial', fontSize: '7pt' }}>-BLANK-</td>
                  <td className="py-2 px-4 text-right border" style={{ color: 'black', fontFamily: 'Verdana', fontSize: '6pt' }}>
                    {currency.format(
                      item?.productId?.sizes?.find(
                        (size) => size.name === item?.size
                      )?.price || 0,
                      { code: "INR" }
                    )}
                  </td>
                  <td className="py-2 px-4 text-right border" style={{ color: 'black', fontFamily: 'Verdana', fontSize: '6pt' }}>
                    {currency.format(
                      discount(
                        item?.productId?.sizes?.find(
                          (size) => size.name === item?.size
                        )?.price || 0,
                        item?.productId?.discount
                      ),
                      { code: "INR" }
                    )}
                  </td>
                  <td className="py-2 px-4 text-center border" style={{ color: 'black', fontFamily: 'Verdana', fontSize: '7pt' }}>{item?.quantity}</td>
                  <td className="py-2 px-4 text-right border" style={{ color: 'black', fontFamily: 'Verdana', fontSize: '7pt' }}>0.00</td>
                  <td className="py-2 px-4 text-right border" style={{ color: 'black', fontFamily: 'Verdana', fontSize: '7pt' }}>0.00</td>
                  <td className="py-2 px-4 text-right border" style={{ color: 'black', fontFamily: 'Verdana', fontSize: '7pt' }}>
                    {currency.format(
                      discount(
                        item?.productId?.sizes?.find(
                          (size) => size.name === item?.size
                        )?.price || 0,
                        item?.productId?.discount
                      ),
                      { code: "INR" }
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
  
        {/* Totals Section */}
        <div className="flex justify-between mb-8">
          <div className="text-sm" style={{ color: 'black', fontFamily: 'Arial Narrow', fontSize: '7pt' }}>
            {/* <p>GST {currency.format(data?.details?.totalAmount, { code: "INR" })} *0%=0SGST</p> */}
          </div>
          <div className="w-64 space-y-2">
            <div className="flex justify-between">
              <span className="font-bold" style={{ color: 'black', fontFamily: 'Arial', fontSize: '9pt' }}>SUB TOTAL</span>
              <span style={{ color: 'black', fontFamily: 'Verdana', fontSize: '9pt', fontWeight: 'bold' }}>
                {currency.format(data?.details?.totalAmount, { code: "INR" })}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-bold" style={{ color: 'black', fontFamily: 'Arial', fontSize: '10pt' }}>Shipping Charges</span>
              <span style={{ color: 'black', fontFamily: 'Arial', fontSize: '10pt', fontWeight: 'bold' }}> {currency.format(data?.details?.shipping, { code: "INR" })}</span>
            </div>
            <div className="flex justify-between border-t pt-2">
              <span className="font-bold" style={{ color: 'black', fontFamily: 'Arial', fontSize: '10pt' }}>GRAND TOTAL</span>
              <span style={{ color: 'black', fontFamily: 'Arial', fontSize: '10pt', fontWeight: 'bold' }}>
                {currency.format(data?.details?.totalAmount, { code: "INR" })}
              </span>
            </div>
          </div>
        </div>
  
        {/* Terms and Customer Details */}
        <div className="grid grid-cols-2 gap-8">
          <div>
            <h3 className="font-bold underline mb-2" style={{ color: 'black', fontFamily: 'Arial', fontSize: '10pt', fontStyle: 'italic' }}>
              Terms & Conditions
            </h3>
            <ul className="space-y-1" style={{ color: 'black', fontFamily: 'Verdana', fontSize: '8pt' }}>
              <li>PRODUCTS WILL BE REPLACED WITHIN 5 DAYS.</li>
              <li>PRODUCTS WILL BE REPLACED ONE TIME ONLY.</li>
              <li>PRODUCTS WILL NOT BE RETURNED.</li>
              <li>FOR FURTHER SUPPORT REACH OUT TO US AT 8989507009</li>
              <li>DELIVERY CHARGES FREE ON ORDER ABOVE INR 499</li>
              
            </ul>
            <div className="border-t pt-4 text-left" style={{ color: 'black', fontFamily: 'Arial', fontSize: '10pt', fontWeight: 'bold',width:"100%" }}>
              <p>Customer Name: {data?.details?.userId?.name}</p>
              <p>Customer Contact: {data?.details?.userId?.phone}</p>
              <p className="mt-2">Shipping Address:</p>
              <p>{data?.details?.address?.addressLine1}</p>
              <p>{data?.details?.address?.city}</p>
              <p>{data?.details?.address?.state}</p>
              <p>{data?.details?.address?.postalCode}</p>
            </div>
          </div>
          <div>
        
            <div className="text-right mb-8">
              <p className="font-bold mb-4 mt-10"  style={{ color: 'black', fontFamily: 'Arial', fontSize: '7pt' }}>For THE BOOK WALE</p>
              <p className="font-bold mt-20" style={{ color: 'black', fontFamily: 'Arial', fontSize: '8pt' }}>Authorised Signatory</p>
            </div>
            
          </div>
        </div>
      </div>
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};
export default OrderDetails;
