import { useParams, Link } from "react-router-dom";
import ScreenHeader from "../../components/ScreenHeader";
import Wrapper from "./Wrapper";
import { useGetOrdersQuery } from "../../store/services/orderService";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import currency from "currency-formatter";
import { discount } from "../../utils/discount";
const Orders = () => {
  let { page } = useParams();
  page = page ? page : 1;
  const { data, isFetching } = useGetOrdersQuery();
  console.log(data);
  return (
    <Wrapper>
      <ScreenHeader>Orders</ScreenHeader>
      {!isFetching ? (
        data?.orders?.length > 0 && (
          <>
            <div className="overflow-x-auto">
  <table className="dashboard-table w-full table-fixed border-collapse">
    <thead>
      <tr className="dashboard-tr bg-gray-700 text-white">
        <th className="dashboard-th w-[40px]">S. No.</th>
        <th className="dashboard-th w-[120px]">Order ID</th>
        <th className="dashboard-th w-[160px]">Images</th>
        <th className="dashboard-th w-[200px]">Product</th>
        <th className="dashboard-th w-[150px]">Customer</th>
        <th className="dashboard-th w-[50px]">Quantities</th>
        <th className="dashboard-th w-[120px]">Amount</th>
        <th className="dashboard-th w-[120px]">Payment Method</th>
        <th className="dashboard-th w-[150px]">Date & Time</th>
        <th className="dashboard-th w-[100px]">Status</th>
        <th className="dashboard-th w-[100px]">Details</th>
      </tr>
    </thead>
    <tbody>
      {data?.orders?.map((order, index) => (
        <tr className="odd:bg-gray-800 even:bg-gray-900" key={order?._id}>
          <td className="dashboard-td text-center">{index + 1}</td>
          <td className="dashboard-td text-center">
            <Link to={`/dashboard/order-summary/${order?._id}`} className="text-blue-400">
              {order?.orderId}
            </Link>
          </td>
          {/* Images Column */}
          <td className="dashboard-td whitespace-nowrap flex items-center justify-start overflow-hidden space-x-2 flex-wrap">
            {order.products.slice(0, 4).map((item, idx) => (
              <div key={idx} className="w-[40px] h-[40px] overflow-hidden rounded-full border border-gray-300">
                <img
                  src={`/images/${item?.productId?.images[0]}`}
                  alt="product"
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </td>
          <td className="dashboard-td truncate" title={order.products.map((item) => item?.productId?.title).join(', ')}>
            {order.products.map((item) => item?.productId?.title).join(', ')}
          </td>
          <td className="dashboard-td text-center">{order?.userId?.name}</td>
          <td className="dashboard-td text-center">
            {order.products.reduce((sum, item) => sum + item.quantity, 0)}
          </td>
          <td className="dashboard-td text-center">{order?.totalAmount}</td>
          <td className="dashboard-td text-center">
            {order?.paymentType === "COD" ? "Cash On Delivery" : "Online"}
          </td>
          <td className="dashboard-td text-center">
            {order?.updatedAt.split('T')[0]}<br />{order?.updatedAt.split('T')[1].split('.')[0]}
          </td>
          <td className="dashboard-td text-center">
            {{0: "Pending", 1: "Shipped", 2: "Delivered", 3: "Cancelled"}[order?.status] || "Unknown"}
          </td>
          <td className="dashboard-td text-center">
            <Link
              to={`/dashboard/order-details/${order?._id}`}
              className="btn btn-warning bg-indigo-600 text-xs font-bold px-2 py-1 rounded"
            >
              Details
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

            <Pagination
              page={parseInt(page)}
              perPage={data.perPage}
              count={data.count}
              path="dashboard/orders"
            />
          </>
        )
      ) : (
        <Spinner />
      )}
    </Wrapper>
  );
};

export default Orders;
