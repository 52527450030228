// import React, { useEffect, useState } from "react";
// import { BsCheckCircleFill } from "react-icons/bs";
// import { Link, useNavigate } from "react-router-dom";
// import { logoLight } from "../../assets/images";
// import { useDispatch } from "react-redux";
// import { setUserToken } from "../../store/reducers/authReducer";
// import { useUserRegisterMutation } from "../../store/services/authService";
// import toast, { Toaster } from "react-hot-toast";
// import Image from "../../components/designLayouts/Image";
// const SignUp = () => {

//   const statesOfIndia = [
//     "Andhra Pradesh",
//     "Arunachal Pradesh",
//     "Assam",
//     "Bihar",
//     "Chhattisgarh",
//     "Goa",
//     "Gujarat",
//     "Haryana",
//     "Himachal Pradesh",
//     "Jharkhand",
//     "Karnataka",
//     "Kerala",
//     "Madhya Pradesh",
//     "Maharashtra",
//     "Manipur",
//     "Meghalaya",
//     "Mizoram",
//     "Nagaland",
//     "Odisha",
//     "Punjab",
//     "Rajasthan",
//     "Sikkim",
//     "Tamil Nadu",
//     "Telangana",
//     "Tripura",
//     "Uttar Pradesh",
//     "Uttarakhand",
//     "West Bengal",
//     "Andaman and Nicobar Islands",
//     "Chandigarh",
//     "Dadra and Nagar Haveli and Daman and Diu",
//     "Delhi",
//     "Lakshadweep",
//     "Puducherry",
//   ];
//   // ============= Initial State Start here =============
//   const [clientName, setClientName] = useState("");
//   const [email, setEmail] = useState("");
//   const [phone, setPhone] = useState("");
//   const [password, setPassword] = useState("");
//   const [address, setAddress] = useState("");
//   const [city, setCity] = useState("");
//   const [country, setCountry] = useState("");
//   const [zip, setZip] = useState("");
//   const [checked, setChecked] = useState(false);
//   // ============= Initial State End here ===============
//   // ============= Error Msg Start here =================
//   const [errClientName, setErrClientName] = useState("");
//   const [errEmail, setErrEmail] = useState("");
//   const [errPhone, setErrPhone] = useState("");
//   const [errPassword, setErrPassword] = useState("");
//   const [errAddress, setErrAddress] = useState("");
//   const [errCity, setErrCity] = useState("");
//   const [errCountry, setErrCountry] = useState("");
//   const [errZip, setErrZip] = useState("");
//   // ============= Error Msg End here ===================
//   const [successMsg, setSuccessMsg] = useState("");
//   // ============= Event Handler Start here =============
//   const handleName = (e) => {
//     setClientName(e.target.value);
//     setErrClientName("");
//   };
//   const handleEmail = (e) => {
//     setEmail(e.target.value);
//     setErrEmail("");
//   };
//   const handlePhone = (e) => {
//     setPhone(e.target.value);
//     setErrPhone("");
//   };
//   const handlePassword = (e) => {
//     setPassword(e.target.value);
//     setErrPassword("");
//   };
//   const handleAddress = (e) => {
//     setAddress(e.target.value);
//     setErrAddress("");
//   };
//   const handleCity = (e) => {
//     setCity(e.target.value);
//     setErrCity("");
//   };
//   const handleCountry = (e) => {
//     setCountry(e.target.value);
//     setErrCountry("");
//   };
//   const handleZip = (e) => {
//     setZip(e.target.value);
//     setErrZip("");
//   };
//   // ============= Event Handler End here ===============
//   // ================= Email Validation start here =============
//   const EmailValidation = (email) => {
//     return String(email)
//       .toLowerCase()
//       .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
//   };
//   // ================= Email Validation End here ===============

//   const [errors, setErrors] = useState([]);
//   const [registerUser, response] = useUserRegisterMutation();

//   const handleSignUp = (e) => {
//     e.preventDefault();
//     if (checked) {
//       if (!clientName) {
//         setErrClientName("Enter your name");
//       }
//       if (!email) {
//         setErrEmail("Enter your email");
//       } else {
//         if (!EmailValidation(email)) {
//           setErrEmail("Enter a Valid email");
//         }
//       }
//       if (!phone) {
//         setErrPhone("Enter your phone number");
//       }
//       if (!password) {
//         setErrPassword("Create a password");
//       } else {
//         if (password.length < 6) {
//           setErrPassword("Passwords must be at least 6 characters");
//         }
//       }
//       if (!address) {
//         setErrAddress("Enter your address");
//       }
//       if (!city) {
//         setErrCity("Enter your city name");
//       }
//       if (!country) {
//         setErrCountry("Enter the state you are residing");
//       }
//       if (!zip) {
//         setErrZip("Enter the zip code of your area");
//       }
//       // ============== Getting the value ==============
//       if (
//         clientName &&
//         email &&
//         phone &&
//         EmailValidation(email) &&
//         password &&
//         password.length >= 6 &&
//         address &&
//         city &&
//         country &&
//         zip
//       ) {
//         registerUser({
//           email: email,
//           password: password,
// phone:phone,
//           name: clientName,
//           address,
//           city,
//           state:country,
//           zip,
//         });
//         setSuccessMsg(
//           `Hello dear ${clientName}, Welcome you to AARYA BOOK WALE. We received your Sign up request. We are processing to validate your access. Till then stay connected and additional assistance will be sent to you by your mail at ${email}`
//         );
//         setClientName("");
//         setEmail("");
//         setPhone("");
//         setPassword("");
//         setAddress("");
//         setCity("");
//         setCountry("");
//         setZip("");
//       }
//     }
//   };

//   useEffect(() => {
//     if (response.isError) {
//       setErrors(response?.error?.data?.errors);
//     }
//   }, [response?.error?.data]);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   useEffect(() => {
//     if (response.isSuccess) {
//       localStorage.setItem("userToken", response?.data?.token);
//       dispatch(setUserToken(response?.data?.token));
//       toast.success(response.data.msg);
//       navigate("/");
//     }
//   }, [response.isSuccess]);

//   return (
//     <div className="w-full h-screen flex items-center justify-start">
//        <Toaster position="top-right" />
//       <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
//         <div className="w-[450px] h-full bg-blueColor px-10 flex flex-col gap-6 justify-center">
//           <Link to="/">
//           <Image
//                 className="w-28 object-cover "
//                 imgSrc="/images/logo/2.png"
//               />
//           </Link>
//           <div className="flex flex-col gap-1 -mt-1">
//             <h1 className="font-titleFont text-xl font-medium">
//               Get started for free
//             </h1>
//             <p className="text-base">Create your account to access more</p>
//           </div>
//           <div className="w-[300px] flex items-start gap-3">
//             <span className="text-green-500 mt-1">
//               <BsCheckCircleFill />
//             </span>
//             <p className="text-base text-gray-300">
//               <span className="text-white font-semibold font-titleFont">
//                 Get started fast with AARYA BOOK WALE
//               </span>
//               <br />
//               Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab omnis
//               nisi dolor recusandae consectetur!
//             </p>
//           </div>
//           <div className="w-[300px] flex items-start gap-3">
//             <span className="text-green-500 mt-1">
//               <BsCheckCircleFill />
//             </span>
//             <p className="text-base text-gray-300">
//               <span className="text-white font-semibold font-titleFont">
//                 Access all Book Wale services
//               </span>
//               <br />
//               Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab omnis
//               nisi dolor recusandae consectetur!
//             </p>
//           </div>
//           <div className="w-[300px] flex items-start gap-3">
//             <span className="text-green-500 mt-1">
//               <BsCheckCircleFill />
//             </span>
//             <p className="text-base text-gray-300">
//               <span className="text-white font-semibold font-titleFont">
//                 Trusted by online Shoppers
//               </span>
//               <br />
//               Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab omnis
//               nisi dolor recusandae consectetur!
//             </p>
//           </div>
//           <div className="flex items-center justify-between mt-10">
//             <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
//               © AARYA BOOK WALE
//             </p>
//             <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
//               Terms
//             </p>
//             <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
//               Privacy
//             </p>
//             <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
//               Security
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
//         {successMsg ? (
//           <div className="w-[500px]">
//             <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
//               {successMsg}
//             </p>
//             <Link to="/signin">
//               <button
//                 className="w-full h-10 bg-primeColor rounded-md text-gray-200 text-base font-titleFont font-semibold 
//             tracking-wide hover:bg-black hover:text-white duration-300"
//               >
//                 Sign in
//               </button>
//             </Link>
//           </div>
//         ) : (
//           <form className="w-full lgl:w-[500px] h-screen flex items-center justify-center">
//             <div className="px-6 py-4 w-full h-[96%] flex flex-col justify-start overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
//               <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-2xl mdl:text-3xl mb-4">
//                 Create your account
//               </h1>
//               <div className="flex flex-col gap-3">
//                 {/* client name */}
//                 <div className="flex flex-col gap-.5">
//                   <p className="font-titleFont text-base font-semibold text-gray-600">
//                     Full Name
//                   </p>
//                   <input
//                     onChange={handleName}
//                     value={clientName}
//                     className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
//                     type="text"
//                     placeholder="eg. John Doe"
//                   />
//                   {errClientName && (
//                     <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
//                       <span className="font-bold italic mr-1">!</span>
//                       {errClientName}
//                     </p>
//                   )}
//                 </div>
//                 {/* Email */}
//                 <div className="flex flex-col gap-.5">
//                   <p className="font-titleFont text-base font-semibold text-gray-600">
//                     Work Email
//                   </p>
//                   <input
//                     onChange={handleEmail}
//                     value={email}
//                     className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
//                     type="email"
//                     placeholder="john@workemail.com"
//                   />
//                   {errEmail && (
//                     <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
//                       <span className="font-bold italic mr-1">!</span>
//                       {errEmail}
//                     </p>
//                   )}
//                 </div>
//                 {/* Phone Number */}
//                 <div className="flex flex-col gap-.5">
//                   <p className="font-titleFont text-base font-semibold text-gray-600">
//                     Phone Number
//                   </p>
//                   <input
//                     onChange={handlePhone}
//                     value={phone}
//                     className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
//                     type="text"
//                     placeholder="9XXXXXXXXX"
//                   />
//                   {errPhone && (
//                     <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
//                       <span className="font-bold italic mr-1">!</span>
//                       {errPhone}
//                     </p>
//                   )}
//                 </div>
//                 {/* Password */}
//                 <div className="flex flex-col gap-.5">
//                   <p className="font-titleFont text-base font-semibold text-gray-600">
//                     Password
//                   </p>
//                   <input
//                     onChange={handlePassword}
//                     value={password}
//                     className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
//                     type="password"
//                     placeholder="Create password"
//                   />
//                   {errPassword && (
//                     <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
//                       <span className="font-bold italic mr-1">!</span>
//                       {errPassword}
//                     </p>
//                   )}
//                 </div>
//                 {/* Address */}
//                 <div className="flex flex-col gap-.5">
//                   <p className="font-titleFont text-base font-semibold text-gray-600">
//                     Address
//                   </p>
//                   <input
//                     onChange={handleAddress}
//                     value={address}
//                     className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
//                     type="text"
//                     placeholder="road-001, house-115, example area"
//                   />
//                   {errAddress && (
//                     <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
//                       <span className="font-bold italic mr-1">!</span>
//                       {errAddress}
//                     </p>
//                   )}
//                 </div>
//                 {/* City */}
//                 <div className="flex flex-col gap-.5">
//                   <p className="font-titleFont text-base font-semibold text-gray-600">
//                     City
//                   </p>
//                   <input
//                     onChange={handleCity}
//                     value={city}
//                     className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
//                     type="text"
//                     placeholder="Your city"
//                   />
//                   {errCity && (
//                     <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
//                       <span className="font-bold italic mr-1">!</span>
//                       {errCity}
//                     </p>
//                   )}
//                 </div>
//                 {/* Country */}
//                 <div className="flex flex-col gap-.5">
//                   <p className="font-titleFont text-base font-semibold text-gray-600">
//                     State
//                   </p>
//                   <select
//         onChange={handleCountry}
//         value={country}
//         className="w-full h-8 px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
//       >
//         <option value="">Select State</option>
//         {/* Map over the states array to create options */}
//         {statesOfIndia.map((state, index) => (
//           <option key={index} value={state}>
//             {state}
//           </option>
//         ))}
//       </select>
//                   {errCountry && (
//                     <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
//                       <span className="font-bold italic mr-1">!</span>
//                       {errCountry}
//                     </p>
//                   )}
//                 </div>
//                 {/* Zip code */}
//                 <div className="flex flex-col gap-.5">
//                   <p className="font-titleFont text-base font-semibold text-gray-600">
//                     Zip/Postal code
//                   </p>
//                   <input
//                     onChange={handleZip}
//                     value={zip}
//                     className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
//                     type="text"
//                     placeholder="Your country"
//                   />
//                   {errZip && (
//                     <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
//                       <span className="font-bold italic mr-1">!</span>
//                       {errZip}
//                     </p>
//                   )}
//                 </div>
//                 {/* Checkbox */}
//                 <div className="flex items-start mdl:items-center gap-2">
//                   <input
//                     onChange={() => setChecked(!checked)}
//                     className="w-4 h-4 mt-1 mdl:mt-0 cursor-pointer"
//                     type="checkbox"
//                   />
//                   <p className="text-sm text-primeColor">
//                     I agree to the AARYA BOOK WALE{" "}
//                     <span className="text-blue-500">Terms of Service </span>and{" "}
//                     <span className="text-blue-500">Privacy Policy</span>.
//                   </p>
//                 </div>
//                 <button
//                   onClick={handleSignUp}
//                   className={`${
//                     checked
//                       ? "bg-primeColor hover:bg-black hover:text-white cursor-pointer"
//                       : "bg-gray-500 hover:bg-gray-500 hover:text-gray-200 cursor-none"
//                   } w-full text-gray-200 text-base font-medium h-10 rounded-md hover:text-white duration-300`}
//                 >
//                   Create Account
//                 </button>
//                 <p className="text-sm text-center font-titleFont font-medium">
//                   Don't have an Account?{" "}
//                   <Link to="/signin">
//                     <span className="hover:text-blue-600 duration-300">
//                       Sign in
//                     </span>
//                   </Link>
//                 </p>
//               </div>
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SignUp;


import React, { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserToken } from "../../store/reducers/authReducer";
import toast, { Toaster } from "react-hot-toast";
import Image from "../../components/designLayouts/Image";
import OtpInput from "react-otp-input";
import { useUserLoginMutation, useUserRegisterMutation } from "../../store/services/authService";

const SignUp = () => {

  const statesOfIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  // State variables
  const [formData, setFormData] = useState({
    clientName: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    city: "",
    country: "",
    zip: "",
  });
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1); // 1: Form, 2: OTP, 3: Success
  const [errors, setErrors] = useState({});
  const [checked, setChecked] = useState(false);
  const [registerUser] = useUserRegisterMutation();

    const [loginUser, response] = useUserLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  // Handle OTP change
  const handleOtpChange = (otp) => {
    setOtp(otp);
    setErrors((prev) => ({ ...prev, otp: "" }));
  };

  // Validate Step 1 (Form)
  const validateStep1 = () => {
    const newErrors = {};

    if (!formData.clientName) newErrors.clientName = "Name is required";
    // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email))
    //   newErrors.email = "Invalid email";
    if (!formData.phone || formData.phone.length !== 10)
      newErrors.phone = "Invalid phone number";
    if (formData.password.length < 6)
      newErrors.password = "Password must be 6+ characters";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.country) newErrors.country = "State is required";
    if (!formData.zip) newErrors.zip = "ZIP code is required";
    if (!checked) newErrors.terms = "You must accept the terms";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  // Handle Step 1 (Form Submission)
  const handleStep1 = async () => {
    if (validateStep1()) {
      
      try {
        const response = await registerUser({
          email: formData.email,
          password: formData.password,
  phone:formData.phone,
          name: formData.clientName,
          address:formData.address,
          city:formData.city,
          state:formData.country,
          zip:formData.zip,
        }).unwrap();
        
        if (response?.resp) {
          setStep(2); // Move to OTP step
          toast.success("OTP sent to your phone!");
        } else {
            toast.error(response.message || "Failed to send OTP. Please try again.");
        }
    } catch (error) {
        toast.error(error?.data?.message || "Something went wrong. Please try again.");
    }
     
    }
  };


  useEffect(() => {
    if (response.isError) {
      setErrors(response?.error?.data?.errors);
    }
  }, [response?.error?.data]);

  const [apiresp_token, setapiresp_token] = useState(null)
  
  useEffect(() => {
    if (response.isSuccess) {
    setapiresp_token(response)
    }
  }, [response.isSuccess]);

  // Handle Step 2 (OTP Verification)
  const handleStep2 = async () => {
    if (!otp || otp.length !== 6) {
      setErrors((prev) => ({ ...prev, otp: "Invalid OTP" }));
      return;
    }
   
  try {
    const response = await  loginUser({
      phone:formData.phone, otp:otp
  }).unwrap();
console.log(JSON.stringify(response))
    if (response?.token) {
      setapiresp_token(response)
       // Simulate OTP verification
   toast.success("OTP verified successfully!");
   setStep(3); // Move to registration step
    } else {
        toast.error(response.message || "Failed to send OTP. Please try again.");
    }
} catch (error) {
    toast.error(error?.data?.message || "Something went wrong. Please try again.");
}

  
  };

  // Handle Step 3 (Final Registration)
  const handleStep3 = () => {
    localStorage.setItem("userToken", apiresp_token?.data?.token);
    dispatch(setUserToken(apiresp_token?.data?.token));
    toast.success(apiresp_token.data.msg);
    navigate("/");

   
  };

  // Response handling (keep your existing useEffect hooks)

  return (
    <div className="w-full min-h-screen overflow-auto flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600">
      <Toaster position="top-right" />
      <div className="w-1/2 hidden lg:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-blue-800 px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <Image className="w-28 object-cover" imgSrc="/images/logo/2.png" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Stay signed in for more
            </h1>
            <p className="text-base">When you sign in, you are with us!</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Get started with AARYA BOOK WALE
              </span>
              <br />
              Join Aarya Book Wale – Explore, Shop, and Grow!"
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © Aarya Book Wale
              </p>
            </Link>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-1/2 h-full flex items-center justify-center">
        <div className="w-full max-w-2xl bg-white rounded-xl shadow-2xl p-8 m-4">
          <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            {step === 1 ? "Create Your Account" : step === 2 ? "Verify OTP" : "Registration Successful"}
          </h1>

          {step === 1 && (
            <form className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Name */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Full Name</label>
                  <input
                    name="clientName"
                    value={formData.clientName}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder="John Doe"
                  />
                  {errors.clientName && <p className="text-red-500 text-sm">{errors.clientName}</p>}
                </div>

                {/* Email */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder="john@example.com"
                  />
                  {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>

                {/* Phone */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                  <input
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder="9876543210"
                    maxLength="10"
                  />
                  {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                </div>

                {/* Password */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">Password</label>
                  <input
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder="••••••••"
                  />
                  {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                </div>

                {/* Address */}
                <div className="space-y-2 md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">Address</label>
                  <input
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder="Street address"
                  />
                  {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
                </div>

                {/* City */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">City</label>
                  <input
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder="City"
                  />
                  {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
                </div>

                {/* State */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">State</label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select State</option>
                    {statesOfIndia.map((state, index) => (
                      <option key={index} value={state}>{state}</option>
                    ))}
                  </select>
                  {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
                </div>

                {/* ZIP Code */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">ZIP Code</label>
                  <input
                    name="zip"
                    value={formData.zip}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                    placeholder="ZIP code"
                  />
                  {errors.zip && <p className="text-red-500 text-sm">{errors.zip}</p>}
                </div>
              </div>

              {/* Terms Checkbox */}
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  className="w-4 h-4 text-blue-600 rounded focus:ring-blue-500"
                />
                <span className="text-sm text-gray-600">
                  I agree to the Terms and Privacy Policy
                </span>
              </div>
              {errors.terms && <p className="text-red-500 text-sm">{errors.terms}</p>}

              {/* Next Button */}
              <button
                type="button"
                onClick={handleStep1}
                className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors font-semibold"
              >
                Next
              </button>
            </form>
          )}

          {step === 2 && (
            <div className="space-y-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Enter OTP</label>
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  separator={<span className="mx-2">-</span>}
                  inputStyle={{
                    width: '40px',
                    height: '40px',
                    margin: '0 5px',
                    textAlign: 'center',
                    fontSize: '18px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  }}
                  // inputStyle="w-[20px] h-10 mx-5 border border-gray-300 rounded-md text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
                  containerStyle="flex justify-center"
                  renderInput={(props) => <input {...props} />}
                  isInputNum
                />
                {errors.otp && <p className="text-red-500 text-sm">{errors.otp}</p>}
              </div>

              {/* Verify OTP Button */}
              <button
                type="button"
                onClick={handleStep2}
                className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors font-semibold"
              >
                Verify OTP
              </button>
            </div>
          )}

          {step === 3 && (
            <div className="space-y-6">
              <p className="text-center text-green-500 font-medium">
                OTP verified successfully! Click below to complete registration.
              </p>

              {/* Register Button */}
              <button
                type="button"
                onClick={handleStep3}
                className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors font-semibold"
              >
                Complete Registration
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;