import React, { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserToken } from "../../store/reducers/authReducer";
import toast, { Toaster } from "react-hot-toast";
import Image from "../../components/designLayouts/Image";
import OtpInput from "react-otp-input"; // For OTP input
import { useSendOtpMutation, useUserLoginMutation } from "../../store/services/authService";

const SignIn = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [errors, setErrors] = useState({ phoneNumber: "", otp: "" });
  const [loginUser, response] = useUserLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle phone number input
  const handlePhoneNumber = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    setPhoneNumber(value);
    setErrors((prev) => ({ ...prev, phoneNumber: "" }));
  };

  // Handle OTP input
  const handleOtpChange = (otp) => {
    setOtp(otp);
    setErrors((prev) => ({ ...prev, otp: "" }));
  };

  // Validate phone number
  const validatePhoneNumber = () => {
    if (!phoneNumber) {
      setErrors((prev) => ({ ...prev, phoneNumber: "Phone number is required" }));
      return false;
    }
    if (phoneNumber.length !== 10) {
      setErrors((prev) => ({ ...prev, phoneNumber: "Invalid phone number" }));
      return false;
    }
    return true;
  };

  // Handle OTP submission
  const handleOtpSubmit = () => {
    if (!otp || otp.length !== 6) {
      setErrors((prev) => ({ ...prev, otp: "Invalid OTP" }));
      return;
    }
    loginUser({
      phone:phoneNumber, otp:otp
  });
    // toast.success("OTP verified successfully!");
    // navigate("/");
  };


  useEffect(() => {
    if(response.isError) {
       setErrors(response?.error?.data?.errors);
    }
   }, [response?.error?.data])
   useEffect(() => {
    if(response.isSuccess) {
      localStorage.setItem('userToken', response?.data?.token);
      dispatch(setUserToken(response?.data?.token))
      toast.success('Welcome User !');
      navigate('/');
    }
  }, [response.isSuccess])

  const [sendOtp, { isLoading, isError }] = useSendOtpMutation();


  // Handle "Send OTP" button click
  const handleSendOtp = async () => {
    if (!validatePhoneNumber()) return;
    try {
      const response = await sendOtp(phoneNumber).unwrap();
console.log(JSON.stringify(response))
      if (response?.resp) {
          setShowOtpField(true);
          toast.success(response.message || "OTP sent successfully!");
      } else {
          toast.error(response.message || "Failed to send OTP. Please try again.");
      }
  } catch (error) {
      toast.error(error?.data?.message || "Something went wrong. Please try again.");
  }
  };

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600">
      <Toaster position="top-right" />
      <div className="w-1/2 hidden lg:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-blue-800 px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <Image className="w-28 object-cover" imgSrc="/images/logo/2.png" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Stay signed in for more
            </h1>
            <p className="text-base">When you sign in, you are with us!</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started with AARYA BOOK WALE
              </span>
              <br />
              Join Aarya Book Wale – Explore, Shop, and Grow!"
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © Aarya Book Wale
              </p>
            </Link>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 h-full flex items-center justify-center">
        <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">
          <h1 className="text-2xl font-bold text-gray-800 mb-6">Sign In</h1>
          {!showOtpField ? (
            <div className="space-y-6">
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={handlePhoneNumber}
                  placeholder="Enter your phone number"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  maxLength={10}
                />
                {errors.phoneNumber && (
                  <p className="text-sm text-red-500">{errors.phoneNumber}</p>
                )}
              </div>
              <button
                onClick={handleSendOtp}
                className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
              >
                Send OTP
              </button>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium text-gray-700">
                  Enter OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  separator={<span className="mx-2">-</span>}
                  inputStyle={{
                    width: '40px',
                    height: '40px',
                    margin: '0 5px',
                    textAlign: 'center',
                    fontSize: '18px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  }}
                  // inputStyle="w-[20px] h-10 mx-5 border border-gray-300 rounded-md text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
                  containerStyle="flex justify-center"
                  renderInput={(props) => <input {...props} />}
                  isInputNum
                />
                {errors.otp && (
                  <p className="text-sm text-red-500">{errors.otp}</p>
                )}
              </div>
              <button
                onClick={handleOtpSubmit}
                className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
              >
                Verify OTP
              </button>
            </div>
          )}
          <p className="text-sm text-center mt-6 text-gray-600">
            Don't have an account?{" "}
            <Link to="/signup" className="text-blue-600 hover:underline">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;