import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Set the app element for accessibility

const OrderSuccessModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose(); // Close modal
        navigate("/"); // Redirect to home
      }, 3000);

      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [isOpen, onClose, navigate]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg p-6 text-center w-full max-w-md">
        <h2 className="text-2xl font-bold text-green-600">Order Successful!</h2>
        <p className="text-gray-600 mt-2">Thank you for your purchase.</p>
      </div>
    </Modal>
  );
};

export default OrderSuccessModal;
