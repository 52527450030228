import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const paymentService = createApi({
  reducerPath: "payment",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://thebookwale.com/api/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.userToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      sendPayment: builder.mutation({
        query: (cart) => {
          return {
            url: "/create-checkout-session",
            method: "POST",
            body: cart,
          };
        },
      }),
      verifyPayment: builder.query({
        query: (id) => {
          return {
            url: `verify-payment/${id}`,
            method: "GET",
          };
        },
      }),
      placeCashOnDeliveryOrder: builder.mutation({
        query: (orderData) => {
          return {
            url: "/place-cod-order",
            method: "POST",
            body: orderData,
          };
        },
      }),

    };
  },
});
export const { useSendPaymentMutation, useVerifyPaymentQuery,usePlaceCashOnDeliveryOrderMutation } = paymentService;
export default paymentService;
