import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaFacebook, FaYoutube, FaLinkedin, FaTwitter,FaInstagram,FaPinterestP } from "react-icons/fa";
import FooterListTitle from "./FooterListTitle";
import { paymentCard } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import { useAllCategoriesQuery } from "../../../store/services/categoryService";
import { Link } from "react-router-dom";
import { useAddSubscriptionMutation } from "../../../store/services/newsletterSubscriptionService";
import { useSelector } from "react-redux";
import { useGetContactUsByIdQuery } from "../../../store/services/contactusService";
import { IoHomeOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { IoDocumentOutline } from "react-icons/io5";
import { CiPhone } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { useGetInformationUsersQuery } from "../../../store/services/informationService";
import { useCreateContactMutation } from "../../../store/services/contactService";

const Footer = () => {
  const { contactusid } = useSelector((state) => state.authReducer);
  const { data:contactusdata, isFetching:contactusfetch } = useGetContactUsByIdQuery(contactusid);
  const { data:infodata, isFetching:inforfetch } = useGetInformationUsersQuery();
// New state for Name, Email, and Message
const [nameInfo, setNameInfo] = useState("");
const [emailInfo, setEmailInfo] = useState("");
const [messageInfo, setMessageInfo] = useState("");
const [subscription, setSubscription] = useState(false);
const [errMsg, setErrMsg] = useState("");

  const { data, isFetching } = useAllCategoriesQuery();
  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const [createContact, { isLoading }] = useCreateContactMutation(); 

  const handleSubscription = async () => {
    if (emailInfo === "") {
      setErrMsg("Please provide a Phone Number !");
    }  else {
      const response = await createContact({ name: nameInfo, email:emailInfo, message:messageInfo });
      if (response.error) {
        // Handle error response
        console.error("Error:", response.error);
      } else {
      setSubscription(true);
      setErrMsg("");
      setEmailInfo("");
      setNameInfo("")
      setMessageInfo("")
      }

     
    }
  };

  
  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-6 px-4 gap-10">
        <div className="col-span-2">
        <Image
                className="w-25 object-cover mb-5"
                imgSrc="/images/logo/2.png"
              />
          <div className="flex flex-col gap-6">
          <span>
								    <div class="row" style={{alignItems: 'center'}}>
										<div class="col-md-1 col-2">
										<IoHomeOutline />

										</div>
										<div class="col-md-10 col-10 p-0">
											<span>AARYA BOOK WALE</span>
										</div>
									</div>
									<div class="row" style={{alignItems: 'center'}}>
										<div class="col-md-1 col-2">
										<CiLocationOn />
										</div>
										<div class="col-md-10 col-10 p-0">
											<span>{contactusdata?.address}</span>
										</div>
									</div>
									<div class="row" style={{alignItems: 'center'}}>
										<div class="col-md-1 col-2">
										<IoDocumentOutline />
										</div>
										<div class="col-md-10 col-10 p-0">
											<span>{contactusdata?.gstNumber}</span>
										</div>
									</div>
									<div class="row" style={{alignItems: 'center',marginTop: '10px'}}>
										<div class="col-md-1 col-2">
                    <CiPhone />
										</div>
										<div class="col-md-10 col-10 p-0">
											<span>{contactusdata?.phoneNumber},{contactusdata?.anotherPhoneNumber} </span>
										</div>
									</div>
									<div class="row" style={{alignItems: 'center',marginTop: '10px'}}>
										<div class="col-md-1 col-2">
										<CiMail />

										</div>
										<div class="col-md-10 col-10 p-0">
											<span>{contactusdata?.emailAddress},{contactusdata?.anotherEmailAddress} </span>
										</div>
									</div>
								</span>
            {/* <p className="text-base w-full xl:w-[80%]">
            Subscribe to the weekly newsletter for all the latest updates
            </p> */}
            <ul className="flex items-center gap-2">
              <a
                href={contactusdata?.youtubePageLink}
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaYoutube />
                </li>
              </a>
             
              <a
                href={contactusdata?.facebookPageLink}
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a
                href={contactusdata?.linkedinPageLink}
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaLinkedin />
                </li>
              </a>
              <a
                href={contactusdata?.pinterestPageLink}
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaPinterestP />
                </li>
              </a>
              <a
                href={contactusdata?.twitterPageLink}
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaTwitter />
                </li>
              </a>
              <a
                href={contactusdata?.instagramPageLink}
                target="_blank"
                rel="noreferrer"
              >
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaInstagram />
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div>
          <FooterListTitle title="Shop" />
          <ul className="flex flex-col gap-2">

          {data?.categories?.slice(0, 5).map((category, index) => {
            return(
            <Link  to={`/shop/${category.name}`} className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
            {category.name}
            </Link>)
          })}
          </ul>
        </div>
        <div>
          <FooterListTitle title="Information" />
          <ul className="flex flex-col gap-2">
          {infodata?.map((category, index) => {
            return(
            <Link  to={`/${category.url}`} className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
            {category.title}
            </Link>)
          })}
          
          </ul>
        </div>
        <div className="col-span-2 flex flex-col items-center w-full px-4">
  <FooterListTitle title="We value your feedback!" />
  <div className="w-full">
    <p className="text-center mb-4">
      Share your suggestions and feedback to help us improve.
    </p>
    {subscription ? (
      <motion.p
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full text-center text-base font-titleFont font-semibold text-green-600"
      >
        Thank you for your feedback!
      </motion.p>
    ) : (
      <div className="w-full flex flex-col gap-4">
        <input
          onChange={(e) => setNameInfo(e.target.value)}
          value={nameInfo}
          className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
          type="text"
          placeholder="Your Name ...*"
        />
        <input
          onChange={(e) => setEmailInfo(e.target.value)}
          value={emailInfo}
          className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
          type="number"
          maxLength={10}
          placeholder="Your Phone Number ...*"
        />
        <textarea
          onChange={(e) => setMessageInfo(e.target.value)}
          value={messageInfo}
          className="w-full h-24 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none resize-none"
          placeholder="Your Message ...*"
        />
        {errMsg && (
          <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
            {errMsg}
          </p>
        )}
        <button
          onClick={handleSubscription}
          className="bg-white text-lightText w-full h-10 hover:bg-black hover:text-white duration-300 text-base tracking-wide"
        >
          Submit
        </button>
      </div>
    )}

            <Image
              className={`w-[80%] lg:w-[60%] mx-auto ${
                subscription ? "mt-2" : "mt-6"
              }`}
              imgSrc={paymentCard}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
